import React from 'react';
import {
  CloudUploadIcon,
  DocumentReportIcon,
  IdentificationIcon,
  ShareIcon,
  PencilAltIcon,
  DocumentTextIcon,
  CalendarIcon,
  ClockIcon,
} from '@heroicons/react/outline';
import CTAFancy from '../../../components/cta/fancy';
import Layout from '../../../components/layout';
import Header from '../../../components/header';
import SEO from '../../../components/seo';
import FeatureBlock from '../../../components/featureBlocks';

const features = [
  {
    name: 'Developer friendly',
    description:
      "We expect our API has the best level of abstraction and you'll love using it. Let us know.",
    icon: IdentificationIcon,
  },
  {
    name: 'Compliant invoices',
    description:
      'Generate multi-language compliant invoices. With support for electronic invoices.',
    icon: DocumentTextIcon,
  },
  {
    name: 'Auto-reconciliation',
    description: 'Add payments and automatically reconcile your invoices.',
    icon: CloudUploadIcon,
  },
  {
    name: 'Payments',
    description:
      'Add and track payments for your invoices and build workflows on top of it.',
    icon: PencilAltIcon,
  },
  {
    name: 'Reminders',
    description:
      "Use our smart reminders and keep track of overdue invoices and customer's debt.",
    icon: ClockIcon,
  },
  {
    name: 'PDF and E-invoice',
    description:
      'Out-of-the-box support for custom PDFs and Electronic invoices.',
    icon: CalendarIcon,
  },
  {
    name: 'Reporting',
    description:
      "Real-time reporting on invoices and taxes. Use our advanced filters and quick views to get an instant picture of your company's invoices.",
    icon: DocumentReportIcon,
  },
  {
    name: 'Easy exports and integrations',
    description:
      'Export your data to CSV or connect it directly to your accounting/ERP software.',
    icon: ShareIcon,
  },
];

function InvoicingAPI({ location }) {
  return (
    <Layout>
      <SEO
        title="Invoicing API"
        description="Automate your invoicing workflows with a developers-friendly-API. Locally compliant and globally available."
        path={location.pathname}
      />
      <Header
        title={
          <h1 className="text-4xl tracking-tight font-bold text-gray-200 sm:text-4xl md:text-6xl">
            <span className="block">One API to create </span>
            <span className="inline bg-gradient-to-r from-purple-tb-400 bg-purple-tb-400 via-orange-tb-400 bg-clip-text font-display tracking-tight text-transparent">
              locally compliant invoices
            </span>
          </h1>
        }
        text="Create compliant invoices and credit notes using a friendly API. One integration to globally scale your invoicing."
      />
      <FeatureBlock
        features={features}
        title="Create, send invoices and track payments. All with a single API."
      />

      <CTAFancy
        cta="One API integration"
        ctaLink="to automate your invoicing workflows."
      />
    </Layout>
  );
}

export default InvoicingAPI;
